import { DateTime } from "luxon";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { modalActions } from "../../../context/actions/modalActions";
import { sidePanelActions } from "../../../context/actions/sidePanelActions";
import useStore from "../../../context/useStore";
import tools from "../../../helpers/tools";
import Table from "../../common/Table";
import RecipientDetail from "../RecipientDetail/RecipientDetail";
import ModifyRecipientToCrossInterview from "./ModifyRecipientToCrossInterview";
import ReorientationIcon from "./ReorientationIcon";

const ReorientationsList = ({
  reorientations,
  users,
  actions,
  constants,
  openAssignModal,
  myOrgas,
  acceptReo,
  refuseReo,
  keepReo,
  resendReo,
  sendToEp,
  deleteReo,
}) => {
  const { ORIENTATION_STATUS } = constants;
  const [state, dispatch] = useStore();
  const spActions = sidePanelActions(state, dispatch);
  const mActions = modalActions(state, dispatch);
  const me = state.auth.user;

  const showAssignButton = (reo) => {
    return (
      myOrgas.find((o) => o.id == reo.toPartner) &&
      ["ADMIN", "SUPER_ADMIN", "MANAGER_PARTNER", "MANAGER"].includes(
        state.auth.user.role
      )
    );
  };

  const showChoixButtons = (reo) => {
    return (
      (["ADMIN", "SUPER_ADMIN"].includes(me.role) ||
        me.id == reo.receivedBy?.id) &&
      reo.status == "WAITING_TO"
    );
  };

  const showConflictButtons = (reo) => {
    return (
      (["ADMIN", "SUPER_ADMIN"].includes(me.role) ||
        me.id == reo.createdBy?.id) &&
      reo.status == "WAITING_FROM"
    );
  };

  const showModifyButtons = (reo) => {
    return (
      (["ADMIN", "SUPER_ADMIN"].includes(me.role) || !reo.receivedBy?.id) &&
      reo.statusHistorics.length == 1
    );
  };

  const openHistoric = (historic) => {
    spActions.updateSidePanel({
      isOpen: true,
      content: (
        <ul className="list-group">
          {historic.map((hist) => {
            var histName = ORIENTATION_STATUS.find(
              (h) => h.id == hist.status
            ).name;
            return (
              <li key={`listhisto${hist.date}`} className="list-group-item">
                <strong className="mr-2 d-block">
                  <i className="fa fa-chevron-right mr-2" />
                  {DateTime.fromISO(hist.date).toFormat("dd/MM/yyyy HH:mm")}
                </strong>{" "}
                {histName}
              </li>
            );
          })}
        </ul>
      ),
    });
  };

  const openModifyCrossInterview = (el) => {
    mActions.updateModal({
      isOpen: true,
      content: (
        <ModifyRecipientToCrossInterview
          orientationId={el.id}
          reasons={el.reasons}
          organization={el.toPartner}
          recipient={el.userRecipient}
        />
      ),
    });
  };

  const openRecipient = (recipientId) => {
    mActions.updateModal({
      isOpen: true,
      content: (
        <RecipientDetail
          isModalOpen={true}
          recipientId={recipientId}
          isModal={true}
        />
      ),
    });
  };

  return (
    <Table
      fullWidth
      fields={[
        {
          name: "Date",
          path: "*",
          render: (el) => {
            var isLateDue = false;
            if (el.statusHistorics.length > 0) {
              var lastStatusEntry =
                el.statusHistorics[el.statusHistorics.length - 1];
              var daysElapsed = tools.getNbDaysFromNow(lastStatusEntry.date);
              if (lastStatusEntry.status == "WAITING_TO" && daysElapsed < -10) {
                isLateDue = true;
              }
            }
            return (
              <>
                {tools.formatDate(el.createdAt)}
                {isLateDue && (
                  <div
                    className="badge badge-danger"
                    style={{
                      whiteSpace: "pre-wrap",
                      maxWidth: 120,
                      display: "block",
                    }}
                  >
                    <i className="fa fa-exclamation-triangle" />
                    En attente de la partie prenante depuis plus de 10 jours.
                  </div>
                )}
              </>
            );
          },
        },
        {
          name: "Allocataire",
          path: "userRecipient",
          render: (el) => (
            <>
              <Link
                className="btn btn-link"
                target="_blank"
                to={tools.getRecipientLink(el)}
              >
                {tools.getFullname(el)} - {el.postalCode}
              </Link>
              {el.model == "UserRecipientStock" && (
                <div className="text-center">
                  <div className="badge badge-info mx-auto">STOCK</div>
                </div>
              )}
            </>
          ),
        },
        {
          name: "Réorientation",
          path: "*",
          render: (el) => {
            return (
              <ReorientationIcon
                fromPartner={actions.getOrganizationFromId(el.fromPartner).name}
                toPartner={actions.getOrganizationFromId(el.toPartner).name}
                fromAgent={
                  <>
                    <div>{tools.getFullname(el.createdBy)}</div>
                    <div>
                      <a href={`mailto:${el.createdBy?.email}`}>
                        {el.createdBy?.email}
                      </a>
                    </div>
                    <div>{el.createdBy?.phone}</div>
                  </>
                }
                toAgent={
                  <>
                    <div>{tools.getFullname(el.receivedBy)}</div>
                    <div>
                      <a href={`mailto:${el.receivedBy?.email}`}>
                        {el.receivedBy?.email}
                      </a>
                    </div>
                    <div>{el.receivedBy?.phone}</div>
                  </>
                }
              />
            );
          },
        },
        {
          name: "Motifs",
          path: "*",
          render: (el) => {
            const motifs = tools.getMotifs(
              { type: "REORIENTATION_OK", reasons: el.reasons },
              constants
            );
            return (
              <>
                <ul>
                  {motifs
                    .filter((m) => m.reason1 || m.reason2.length > 0)
                    .map((m) => {
                      return (
                        <li>
                          <div>
                            {m.reason1}
                            <ul>
                              {m.reason2.map((r2) => (
                                <li>{r2}</li>
                              ))}
                            </ul>
                          </div>
                        </li>
                      );
                    })}
                </ul>
                {showModifyButtons(el) && (
                  <button
                    className="btn btn-link btn-sm d-block"
                    onClick={() => openModifyCrossInterview(el)}
                  >
                    Modifier les motifs de réo.
                  </button>
                )}
              </>
            );
          },
        },
        {
          name: "Statut",
          path: "*",
          render: (el) => {
            const isAssigned = Object.values(el.receivedBy || {}).length > 0;
            return (
              <div>
                <div>
                  <small>
                    {ORIENTATION_STATUS.find((o) => o.id == el.status).name}{" "}
                    {(el.status == "ORIENTATION_OK" ||
                      el.status == "ORIENTATION_KO") &&
                      el.ep && (
                        <>
                          EP du{" "}
                          {DateTime.fromISO(el.ep.beginAt).toFormat(
                            "dd/MM/yyyy"
                          )}
                          {" à "}
                          {tools.numberWithLeadingZero(el.ep.beginHourAt)}h
                          {tools.numberWithLeadingZero(el.ep.beginMinuteAt)}{" "}
                          secteur {el.ep.sector}
                        </>
                      )}
                  </small>
                </div>
                {showAssignButton(el) && (
                  <button
                    className="btn btn-primary btn-sm mr-1"
                    onClick={() => openAssignModal(el)}
                  >
                    {isAssigned ? "Ré-assigner" : "Assigner"}
                  </button>
                )}
                {showChoixButtons(el) && isAssigned && (
                  <>
                    <button
                      className="btn btn-secondary btn-sm mr-1"
                      onClick={() => acceptReo(el)}
                    >
                      Accepter
                    </button>
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={() => refuseReo(el)}
                    >
                      {el.statusHistorics.find(
                        (l) => l.status == "WAITING_FROM"
                      ) && "Nouvel "}
                      Entretien croisé
                    </button>
                  </>
                )}
                {showConflictButtons(el) && isAssigned && (
                  <>
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={() => keepReo(el)}
                    >
                      Orientation conservée
                    </button>
                    <button
                      className="btn btn-secondary btn-sm mx-1"
                      onClick={() => resendReo(el)}
                    >
                      Renvoi de la réo.
                    </button>
                    {/* <button
                      className="btn btn-secondary btn-sm"
                      onClick={() => sendToEp(el)}
                    >
                      Inscr. en EP
                    </button> */}
                  </>
                )}
                {el.toPartnerFinal && (
                  <div>
                    <hr className="w-100" />
                    <small>Orientation finale</small>{" "}
                    <strong className="text-success">
                      {actions.getOrganizationFromId(el.toPartnerFinal).name}
                    </strong>
                  </div>
                )}

                <button
                  className="btn btn-link btn-sm d-block"
                  onClick={() => openHistoric(el.statusHistorics)}
                >
                  {el.statusHistorics.length} échange(s)
                </button>
                {showModifyButtons(el) ? (
                  <>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => deleteReo(el.id)}
                    >
                      Supprimer cette demande
                    </button>
                  </>
                ) : null}
              </div>
            );
          },
        },
      ]}
      datas={reorientations}
    />
  );
};

export default ReorientationsList;
