import React, { useState } from "react";
import dataService from "../../../../helpers/dataService";

const useNbFirstOrientation = (url, year, otherPostParams) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartState, setChartState] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalML, setTotalML] = useState(0);
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const lineOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: months,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
  };

  const getDatas = () => {
    setIsLoading(true);

    dataService.post(
      `${url}`,
      { year, ...otherPostParams },
      (datas) => {
        var allTotals = datas.global.map((o) => o.months.reduce((a, b) => a + b));
        var mlTotals = datas.ML.map((o) => o.months.reduce((a, b) => a + b));

        setTotal(allTotals.reduce((a, b) => a + b));
        setTotalML(mlTotals.reduce((a, b) => a + b));

        setChartState({
          line1: {
            series: datas.global.map((o) => ({
              name: o.name,
              data: o.months,
            })),
            options: lineOptions,
          },
          pie1: {
            series: datas.global.map((o) => o.months.reduce((a, b) => a + b)),
            options: {
              labels: datas.global.map((o) => o.name),
              legend: { position: "bottom" },
              chart: {
                toolbar: {
                  show: true,
                },
              },
            },
          },
          line2: {
            series: datas.ML.map((o) => ({
              name: o.name,
              data: o.months,
            })),
            options: lineOptions,
          },
          pie2: {
            series: datas.ML.map((o) => o.months.reduce((a, b) => a + b)),
            options: {
              labels: datas.ML.map((o) => o.name),
              legend: { position: "bottom" },
              chart: {
                toolbar: {
                  show: true,
                },
              },
            },
          },
        });
      },
      (e) => {},
      () => setIsLoading(false)
    );
  };

  var hasDatas = chartState;

  return {
    isLoading,
    getDatas,
    chartState,
    hasDatas,
    total,
    totalML,
  };
};

export default useNbFirstOrientation;


/*
SAMPLE DATA : 

{
  "global": [
    {
      "name": "Pole-emploi",
      "months": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ]
    },
    {
      "name": "Missions locales",
      "months": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ]
    },
    {
      "name": "Conseil Départemental",
      "months": [
        0,
        1,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ]
    },
    {
      "name": "CAF",
      "months": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ]
    }
  ],
  "ML": [
    {
      "name": "MISSION LOCALE EST",
      "months": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ]
    },
    {
      "name": "MISSION LOCALE NORD",
      "months": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ]
    },
    {
      "name": "MISSION LOCALE OUEST",
      "months": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ]
    },
    {
      "name": "MISSION LOCALE SUD",
      "months": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ]
    }
  ]
}
*/