import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { modalActions } from "../../../context/actions/modalActions";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";
import DynamicList from "../../common/DynamicList";
import SaveBtn from "../../common/SaveBtn";

const AddRecipientToCrossInterview = ({
  recipient,
  onSaved = () => {},
  organization = null,
  authorizedTargetOrganizationsSlugNames = [],
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  const { EP_REASONS_1, EP_REASONS_2 } = state.constants.items;
  const [errors, setErrors] = useState({});
  const [avis, setAvis] = useState({
    toPartner: organization || "",
    reasons: [],
  });
  const [orgas, setOrgas] = useState([]);

  useEffect(() => {
    getOrgas();
  }, []);

  useEffect(() => {
    setAvis({ ...avis, reasons: [] });
  }, [avis.type]);

  const getOrgas = () => {
    dataService.get(`organizations`, setOrgas);
  };

  const save = () => {
    setIsSaving(true);
    dataService.post(
      `reorientations`,
      {
        userRecipient: recipient.id,
        toPartner: avis.toPartner,
        reasons: avis.reasons,
      },
      (datas) => {
        toast.success("La réorientation de l'allocataire a bien été demandée");
        onSaved({ reorientationPartner: avis.toPartner });
        mActions.updateModal({
          isOpen: false,
          content: null,
          noClose: false,
        });
      },
      setErrors,
      () => setIsSaving(false)
    );
  };

  useEffect(() => {
    let hasInvalid = false;
    avis.reasons.forEach((reas) => {
      reas.reasons2.forEach((rea2) => {
        if (rea2.split("_")[0] != reas.reason1) {
          hasInvalid = true;
        }
      });
    });
    if (hasInvalid) {
      var newReasons = avis.reasons.map((reas) => {
        return {
          ...reas,
          reasons2: reas.reasons2.filter(
            (r2) => r2.split("_")[0] == reas.reason1
          ),
        };
      });
      setAvis({ ...avis, reasons: newReasons });
    }
  }, [avis]);

  let filteredOrgas = orgas.filter(
    (o) => !o.isOnlyEG && o.isOrientationPartner
  );
  if (authorizedTargetOrganizationsSlugNames.length > 0) {
    filteredOrgas = filteredOrgas.filter((o) =>
      authorizedTargetOrganizationsSlugNames.includes(o.slugName)
    );
  }

  if (recipient?.lastOrientation?.orientation) {
    filteredOrgas = filteredOrgas.filter(
      (o) => o.slugName !== recipient?.lastOrientation?.orientation
    );
  }

  return (
    <div className="custom-card">
      <div className="d-flex justify-content-start w-100 flex-column">
        <h4 style={{ fontSize: 16 }}>
          Demander la réorientation de l'allocataire{" "}
          {tools.getFullname(recipient)}
        </h4>

        <Control
          label="Vers quel organisme souhaitez-vous réorienter ?"
          name="organization"
          type="btnList"
          datas={filteredOrgas}
          change={(e) => setAvis({ ...avis, toPartner: e.target.value })}
          value={avis.toPartner}
          disabled={organization}
        />

        <DynamicList
          listLabel="Motif"
          btnLabel="Ajouter un motif"
          uniqueKey="recrights-1"
          name="reasons"
          fieldContainerClassName="d-flex flex-row"
          fields={[
            {
              label: "Motif 1",
              name: "reason1",
              type: "btnList",
              datas: EP_REASONS_1,
              dataIndex: "id",
              dataLabel: "name",
              className: "align-items-start flex-fill w-50",
            },
            {
              label: "Motif 2",
              name: "reasons2",
              type: "checkBtnList",
              btnInline: true,
              datas: EP_REASONS_2.filter((eps) => !eps.isDisabled),
              dataIndex: "id",
              dataLabel: "name",
              filteredBy: "reason1",
              filterFn: (val) => {
                return EP_REASONS_2.filter(
                  (r) => !r.isDisabled && r.reason1 == val
                );
              },
              className: "align-items-start flex-fill w-50",
            },
          ]}
          items={avis.reasons}
          change={(e) => setAvis({ ...avis, reasons: e.target.value })}
          error={{}}
        />

        <div className="d-flex justify-content-end">
          {Object.values(errors).map((err) => (
            <span
              className="badge badge-danger d-center mt-5 mr-2 rounded"
              key={`error${err}`}
            >
              {err}
            </span>
          ))}
          <SaveBtn
            save={save}
            isSaving={isSaving}
            text={`Demander la réorientation de ${tools.getFullname(
              recipient
            )}`}
          />
        </div>
      </div>
    </div>
  );
};

export default AddRecipientToCrossInterview;
