import { DateTime } from "luxon";

const SynchroFTStatus = ({ sentToFTAt }) => {
  return sentToFTAt ? (
    <span className="badge badge-success">
      <i className="fa fa-link mr-2" />
      (synchronisé avec FT le{" "}
      {DateTime.fromISO(sentToFTAt).toFormat("dd/MM/yyyy")})
    </span>
  ) : (
    <span className="badge badge-danger">
      <i className="fa fa-unlink mr-2" />
      non synchronisé avec FT
    </span>
  );
};

export default SynchroFTStatus;
