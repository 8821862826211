import React, { useEffect, useState } from "react";
import dataService from "../../../helpers/dataService";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import downloadTools from "../../../helpers/useDownload";
import { toast } from "react-toastify";
import useStore from "../../../context/useStore";
import { modalActions } from "../../../context/actions/modalActions";
import ImportFastResults from "./ImportFastResults";
import OrientationBlock from "./OrientationBlock";
import FastBlock from "./FastBlock";
import { DateTime } from "luxon";
import EpBlock from "./EpBlock";
import FastEpBlock from "./FastEpBlock";
import EgBlock from "./EGBlock";
import FastStockBlock from "./FastStockBlock";

const FileExchange = () => {
  const [state, dispatch] = useStore();
  const modal_actions = modalActions(state, dispatch);
  const [isLoading, setIsLoading] = useState(false);
  const [partners, setPartners] = useState([]);
  const [isLoadingAction, setIsLoadingAction] = useState([]);
  const [errors, setErrors] = useState({});
  const [results, setResults] = useState({});
  const [resErrors, setResErrors] = useState({});
  const [fastFileName, setFastFileName] = useState("");
  const [fastEpFileName, setFastEpFileName] = useState("");
  const [fastStockFileName, setFastStockFileName] = useState("");

  useEffect(() => {
    setIsLoading(true);
    dataService.get(
      "organizations/orientation_partners",
      setPartners,
      setErrors,
      () => setIsLoading(false)
    );
  }, []);

  const handleError = (err, el) => {
    if (err.other) {
      toast.error(err.other);
    }

    popLoading(el);
  };

  const actions = {
    export: {
      op: {
        download: async (slugName, day, dayEnd) => {
          setIsLoadingAction([...isLoadingAction, "od" + slugName]);
          await downloadTools
            .downloadFile(
              `exchanges/partners/${slugName}`,
              {
                beginAt: day,
                endAt: dayEnd,
                isFast: false,
              },
              `orientation-${slugName}-${DateTime.fromISO(day).toFormat(
                "yyyyMMdd"
              )}.csv`
            )
            .then((r) => popLoading("od"))
            .catch((err) => handleError(err, "od"));
        },
        export: (slugName, day, dayEnd) => {
          setIsLoadingAction([...isLoadingAction, "oe" + slugName]);
          dataService.post(
            `exchanges/partners/${slugName}`,
            {
              beginAt: day,
              endAt: dayEnd,
              isFast: true,
            },
            (datas) => {
              popLoading("od");
              toast.success("Données exportées avec succès");
            },
            (err) => handleError("Erreur", "od")
          );
        },
      },
      ep: {
        download: async (slugName) => {
          setIsLoadingAction([...isLoadingAction, "epd"]);
          await downloadTools
            .downloadFile(
              `exchanges/userrecipienteps/export`,
              {
                isUpdateSID: false,
              },
              `allocataires-ep.csv`
            )
            .then((r) => popLoading("epd"))
            .catch((err) => handleError(err, "epd"));
        },
        export: () => {
          setIsLoadingAction([...isLoadingAction, "epe"]);
          dataService.post(
            `exchanges/userrecipienteps/export`,
            {
              isUpdateSID: true,
            },
            (datas) => {
              popLoading("epd");
              toast.success("Données exportées avec succès");
            },
            (err) => handleError("Erreur", "epd")
          );
        },
      },
      eg: {
        download: async (day, dayEnd) => {
          setIsLoadingAction([...isLoadingAction, "egd"]);
          await downloadTools
            .downloadFile(
              `exchanges/user-recipients-from-eg/export`,
              {
                beginAt: day,
                endAt: dayEnd,
                isFast: false,
              },
              `allocataires-ep.csv`
            )
            .then((r) => popLoading("egd"))
            .catch((err) => {
              handleError(
                { other: "Aucune donnée ne correspond à votre demande" },
                "egd"
              );
            });
        },
        export: (day, dayEnd) => {
          setIsLoadingAction([...isLoadingAction, "ege"]);
          dataService.post(
            `exchanges/user-recipients-from-eg/export`,
            {
              beginAt: day,
              endAt: dayEnd,
              isFast: true,
            },
            (datas) => {
              popLoading("ege");
              toast.success("Données exportées avec succès");
            },
            (err) => handleError("Erreur", "ege")
          );
        },
      },
      campain: {
        download: () => {
          setIsLoadingAction([...isLoadingAction, "cd"]);
        },
        export: () => {
          setIsLoadingAction([...isLoadingAction, "ce"]);
        },
      },
      stat: {
        download: () => {
          setIsLoadingAction([...isLoadingAction, "sd"]);
        },
        export: () => {
          setIsLoadingAction([...isLoadingAction, "se"]);
        },
      },
    },
    import: {
      temp: () => {
        var el = "it";
        setIsLoadingAction([...isLoadingAction, el]);
        dataService.post(
          `exchanges/fast`,
          { fastFileName },
          (datas) => setResults({ ...results, it: datas }),
          (err) => setResErrors({ ...resErrors, it: err }),
          () => popLoading(el)
        );
      },
      ep: () => {
        var el = "ep";
        setIsLoadingAction([...isLoadingAction, el]);
        dataService.post(
          `exchanges/userrecipienteps/import`,
          { fileName: fastEpFileName },
          (datas) => setResults({ ...results, ep: datas }),
          (err) => setResErrors({ ...resErrors, ep: err }),
          () => popLoading(el)
        );
      },
      stock: () => {
        var el = "stock";
        setIsLoadingAction([...isLoadingAction, el]);
        dataService.post(
          `exchanges/user-recipient-stocks/import`,
          { fileName: fastStockFileName },
          (datas) => {
            toast.success(`${datas.length} entrées importées avec succès`);
            //setResults({ ...results, stock: datas })
          },
          (err) => setResErrors({ ...resErrors, stock: err }),
          () => popLoading(el)
        );
      },
    },
  };

  const popLoading = (el) => {
    var is = isLoadingAction.filter((i) => i != el);
    setIsLoadingAction(is);
  };

  useEffect(() => {
    if (results.it) {
      modal_actions.updateModal({
        isOpen: true,
        content: <ImportFastResults datas={results.it} />,
      });
    }
  }, [results.it]);

  useEffect(() => {
    if (!state.modal.isOpen) {
    }
  }, [state.modal.isOpen]);

  return isLoading ? (
    <Loader />
  ) : (
    <Page
      container={"container-fluid px-5"}
      title="Fichiers d'échange"
      errors={errors}
    >
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="d-center my-2 text-primary ">
                ESPOAR <i className="fa fa-arrow-right mx-2" />
                <i className="fa fa-database" />
              </h4>
              <OrientationBlock
                actions={actions}
                partners={partners}
                isLoadingAction={isLoadingAction}
              />
              {/* <EpBlock actions={actions} isLoadingAction={isLoadingAction} /> */}
              <EgBlock actions={actions} isLoadingAction={isLoadingAction} />
              {/* <CampainBlock
                actions={actions}
                isLoadingAction={isLoadingAction}
              />
              <StatBlock actions={actions} isLoadingAction={isLoadingAction} /> */}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="d-center my-2 text-success">
                <i className="fa fa-database" />{" "}
                <i className="fa fa-arrow-right mx-2" />
                ESPOAR
              </h4>
              <FastBlock
                actions={actions}
                isLoadingAction={isLoadingAction}
                fastFileName={fastFileName}
                setFastFileName={setFastFileName}
                errors={errors}
              />
              {/* <FastEpBlock
                actions={actions}
                isLoadingAction={isLoadingAction}
                fastEpFileName={fastEpFileName}
                setFastEpFileName={setFastEpFileName}
                errors={errors}
              /> */}
              <FastStockBlock
                actions={actions}
                isLoadingAction={isLoadingAction}
                fastStockFileName={fastStockFileName}
                setFastStockFileName={setFastStockFileName}
                errors={errors}
              />
              {/* <RdvBlock actions={actions} isLoadingAction={isLoadingAction} /> */}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default FileExchange;
