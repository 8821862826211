import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { modal2Actions } from "../../../../context/actions/modal2Actions";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Control from "../../../common/Control";
import DynamicList from "../../../common/DynamicList";
import SaveBtn from "../../../common/SaveBtn";

const ModifyMotifs = ({ epDatas, getEpRecipients }) => {
  const recipient = epDatas.userRecipient;
  const [isSaving, setIsSaving] = useState(false);
  const [state, dispatch] = useStore();
  const mActions = modal2Actions(state, dispatch);
  const {
    EP_TYPES,
    EP_REASONS_1,
    EP_REASONS_2,
    EP_SANCTION_REASONS_1,
    EP_SANCTION_REASONS_2,
    EP_ADDITIONAL_REASONS,
  } = state.constants.items;
  const [errors, setErrors] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [avis, setAvis] = useState(
    epDatas || {
      type: "",
      crossInterviewType: "",
      additionalReasons: [],
      reasons: [],
      organizationOrigin: "",
    }
  );
  const [myOrganizations, setMyOrganizations] = useState([]);

  useEffect(() => {
    dataService.get(`organizations/orientation_partners`, (datas) => {
      setMyOrganizations(datas);
      if (datas.length == 1) {
        setAvis({ ...avis, organizationOrigin: datas[0].id });
      }
    });
  }, []);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      setAvis({ ...avis, reasons: [] });
    }
  }, [avis.type]);

  const save = () => {
    setIsSaving(true);
    dataService.patch(
      `epdetails/${epDatas.id}`,
      {
        type: avis.type,
        reasons: avis.reasons,
        additionalReasons: avis.additionalReasons,
        organizationOrigin: avis.organizationOrigin,
      },
      (datas) => {
        toast.success("Les motifs d'inscription ont bien été modifiés");
        try {
          getEpRecipients();
        } catch (err) {}
        mActions.updateModal({
          isOpen: false,
          content: null,
        });
      },
      setErrors,
      () => setIsSaving(false)
    );
  };

  return (
    <>
      <div className="custom-card">
        <div className="d-flex justify-content-start w-100 flex-column">
          <h4 style={{ fontSize: 16 }}>
            Inscription de {tools.getFullname(recipient)} à la prochaine EP
          </h4>
          <Control
            label="Quel est l'organisme de saisine ?"
            value={avis.organizationOrigin}
            type="btnList"
            dataIndex="id"
            dataLabel="name"
            datas={myOrganizations}
            change={(e) =>
              setAvis({ ...avis, organizationOrigin: e.target.value })
            }
            btnInline
            disabled
          />
          <Control
            label="Raison de l'inscription"
            name="type"
            type="btnList"
            datas={EP_TYPES.filter(
              (type) =>
                type.id != "REORIENTATION_OK" &&
                type.id != "REORIENTATION_KO" &&
                type.id != "SOCIAL_PATH_MAINTAIN"
            )}
            dataIndex="id"
            dataLabel="name"
            value={avis.type}
            change={(e) => setAvis({ ...avis, type: e.target.value })}
          />
          {["SANCTION"].includes(avis.type) && (
            <>
              <DynamicList
                listLabel={
                  <>
                    Motifs <span className="text-danger ml-1">*</span>
                  </>
                }
                btnLabel="Ajouter un motif"
                uniqueKey="recrights-1"
                name="reasons"
                fieldContainerClassName="d-flex flex-row"
                fields={[
                  {
                    label: "Motif 1",
                    name: "reason1",
                    type: "btnList",
                    datas: EP_SANCTION_REASONS_1.filter(
                      (eps) => !eps.isDisabled
                    ),
                    dataIndex: "id",
                    dataLabel: "name",
                    className: "align-items-start flex-fill w-50",
                  },
                  {
                    label: "Motif 2",
                    name: "reasons2",
                    type: "checkBtnList",
                    btnInline: true,
                    datas: EP_SANCTION_REASONS_2,
                    dataIndex: "id",
                    dataLabel: "name",
                    className: "align-items-start flex-fill w-50",
                  },
                ]}
                items={avis.reasons}
                change={(e) => setAvis({ ...avis, reasons: e.target.value })}
                error={{}}
              />
            </>
          )}
          {!["SANCTION", ""].includes(avis.type) && (
            <>
              <DynamicList
                listLabel="Motifs"
                btnLabel="Ajouter un motif"
                uniqueKey="recrights-1"
                name="reasons"
                fieldContainerClassName="d-flex flex-row"
                fields={[
                  {
                    label: "Motif 1",
                    name: "reason1",
                    type: "btnList",
                    datas: EP_REASONS_1,
                    dataIndex: "id",
                    dataLabel: "name",
                    className: "align-items-start flex-fill w-50",
                  },
                  {
                    label: "Motif 2",
                    name: "reasons2",
                    type: "checkBtnList",
                    btnInline: true,
                    datas: EP_REASONS_2.filter((eps) => !eps.isDisabled),
                    dataIndex: "id",
                    dataLabel: "name",
                    filteredBy: "reason1",
                    filterFn: (val) => {
                      return EP_REASONS_2.filter(
                        (r) => !r.isDisabled && r.reason1 == val
                      );
                    },
                    className: "align-items-start flex-fill w-50",
                  },
                ]}
                items={avis.reasons}
                change={(e) => setAvis({ ...avis, reasons: e.target.value })}
                error={{}}
              />
            </>
          )}
          <Control
            label="Motifs complémentaires de saisine (optionnels)"
            name="additionalReasons"
            type="checkBtnList"
            datas={EP_ADDITIONAL_REASONS}
            dataIndex="id"
            dataLabel="name"
            value={avis.additionalReasons.map((rea) => parseInt(rea))}
            change={(e) => {
              if (avis.additionalReasons.includes(e.target.value)) {
                setAvis({
                  ...avis,
                  additionalReasons: avis.additionalReasons.filter(
                    (r) => r != e.target.value
                  ),
                });
              } else {
                setAvis({
                  ...avis,
                  additionalReasons: [
                    ...avis.additionalReasons,
                    e.target.value,
                  ],
                });
              }
            }}
          />
          <div className="d-flex justify-content-end">
            {Object.values(errors).map((err) => (
              <span
                className="badge badge-danger d-center mt-5 mr-2 rounded"
                key={`error${err}`}
              >
                {err}
              </span>
            ))}
            <SaveBtn
              save={save}
              isSaving={isSaving}
              text={`Enregistrer ${tools.getFullname(
                recipient
              )} les modifications`}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          zIndex: "999999",
          bottom: 60,
          right: 30,
        }}
      >
        <SaveBtn
          className="p-2"
          save={save}
          isSaving={isSaving}
          text={<i className="fa fa-2x fa-save" />}
        />
      </div>
    </>
  );
};

export default ModifyMotifs;
