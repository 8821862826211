import React from "react";
import useStore from "../../../context/useStore";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";
import SimpleDynamicList from "../../common/SimpleDynamicList";

const ReorientationsFilters = ({
  search,
  updateSearch,
  organizations,
  fromSites,
  toSites,
  fetchReorientations,
  isLoading,
  nbResults,
}) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const { ORIENTATION_STATUS } = constants;

  return (
    <div className="row mb-3">
      <div className="col-12 col-md-6">
        <Control
          label="Organisme cédant"
          name="fromPartner"
          value={search.filters.fromPartner}
          change={updateSearch}
          type="select"
          datas={organizations}
          dataIndex="id"
          dataLabel="name"
        />
        {search.filters.fromPartner && (
          <SimpleDynamicList
            listLabel="Sites Cédants (tous si aucun sélectionné)"
            element="fromSites"
            items={search.filters.fromSites || []}
            fields={[
              {
                name: "name",
                type: "select",
                datas: fromSites,
                dataIndex: "id",
                dataLabel: "name",
              },
            ]}
            change={updateSearch}
            btnLabel="Ajouter un site"
          />
        )}

        <Control
          label="Statut de la réo."
          type="select"
          datas={ORIENTATION_STATUS}
          dataIndex="id"
          dataLabel="name"
          value={search.filters.status}
          change={updateSearch}
          name="status"
        />
        <Control
          label="Afficher les réorientations terminées"
          name="isFinished"
          checked={search.filters.isFinished}
          change={updateSearch}
          type="checkbox"
        />
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="Organisme prenant"
          name="toPartner"
          value={search.filters.toPartner}
          change={updateSearch}
          type="select"
          datas={organizations.filter((o) => o.slugName != "CAF_EG")}
          dataIndex="id"
          dataLabel="name"
        />

        {search.filters.toPartner && (
          <SimpleDynamicList
            listLabel="Sites Prenants (tous si aucun sélectionné)"
            element="toSites"
            items={search.filters.toSites || []}
            fields={[
              {
                name: "name",
                type: "select",
                datas: toSites,
                dataIndex: "id",
                dataLabel: "name",
              },
            ]}
            change={updateSearch}
            btnLabel="Ajouter un site"
          />
        )}
        <Control
          label="Uniquement les réorientations qui me sont assignées"
          name="receivedBy"
          checked={search.filters.receivedBy}
          change={updateSearch}
          type="checkbox"
          margin="my-4"
        />
        {/* <Control
          label="Allocataire avec un EP en cours"
          type="select"
          name="isReorientationInProgress"
          datas={[
            { id: "true", name: "Oui" },
            { id: "false", name: "Non" },
          ]}
          dataIndex="id"
          dataLabel="name"
          selectFirstLabel="Tous"
          value={search.isReorientationInProgress}
          change={updateSearch}
        /> */}
      </div>
      <div className="col-12 mt-3">
        <SaveBtn
          margin="mt-0 mx-2 py-3 font-weight-bold"
          className=" w-100"
          type="btn-primary d-center"
          save={fetchReorientations}
          isSaving={isLoading}
          text="Rechercher"
        />
      </div>
      <div className="col-12 text-center mt-2">
        <strong>{nbResults} résultats</strong>
      </div>
    </div>
  );
};

export default ReorientationsFilters;
