const sidenavItems = [
  {
    name: "Rendez-vous",
    path: "/rdvs",
    icon: "calendar-alt",
    dataPriv: "manager_appointments",
  },
  {
    name: "Rechercher un allocataire",
    path: "/all-recipients",
    icon: "users",
    dataPriv: "view_recipients",
  },
  {
    name: "Allocataires",
    path: "/recipients",
    icon: "users",
    dataPriv: "view_recipients",
  },
  {
    name: "Allocataires temporaires",
    path: "/recipients-temp",
    icon: "users",
    dataPriv: "view_recipients_temp",
  },
  {
    name: "Allocataires stock",
    path: "/recipients-stock",
    icon: "users",
    dataPriv: "view_recipients_temp",
  },
  // {
  //   name: "Allocataires passés en EP",
  //   path: "/recipients-ep-results",
  //   icon: "users",
  //   dataPriv: "view_recipients_temp",
  // },

  {
    name: "Statistiques",
    path: "/stats",
    icon: "chart-bar",
    dataPriv: "view_stats",
  },
  {
    name: "Réorientations en cours",
    path: "/reorientations",
    icon: "map-signs",
    dataPriv: "crud_reorientations",
  },

  {
    name: "Entités globales",
    path: "#",
    items: [
      {
        name: "Utilisateurs",
        path: "/users",
        icon: "user",
        dataPriv: "crud_users",
      },
      {
        name: "Organisations",
        path: "/organizations",
        icon: "sitemap",
        dataPriv: "crud_organizations",
      },
      {
        name: "Sites / Calendriers",
        path: "/sites",
        icon: "map-marker-alt",
        dataPriv: "view_sites",
      },
    ],
  },
  {
    name: "Anomalies",
    path: "#",
    items: [
      {
        name: "Anomalies",
        path: "/anomalies",
        icon: "exclamation-triangle",
        dataPriv: "view_anomalies",
      },
      {
        name: "Mes anomalies",
        path: "/my-anomalies",
        icon: "exclamation-triangle",
        dataPriv: "manage_anomalies",
      },
      {
        name: "Suivi BRSA SDD sans RDSP ou sans RDV EG",
        path: "/suivi-anomalies",
        icon: "exclamation-triangle",
        dataPriv: "manage_anomalies",
      },
      {
        name: "Anomalies API FT",
        path: "/anomalies-orientation-ft",
        icon: "exclamation-triangle",
        dataPriv: "view_anomalies",
      },
    ],
  },
  {
    name: "Opérateurs",
    path: "#",
    items: [
      {
        name: "Opérateurs",
        path: "/operators",
        icon: "user",
        dataPriv: "crud_operators",
      },
      {
        name: "Activités opérateur",
        path: "/themes",
        icon: "atom",
        dataPriv: "crud_themes",
      },
    ],
  },

  {
    name: "CER",
    path: "#",
    items: [
      {
        name: "Données CER",
        path: "/cer-data",
        icon: "cog",
        dataPriv: "modify_constants",
      },
      {
        name: "Liste des CER",
        path: "/cer-list",
        icon: "signature",
        dataPriv: "view_cer_list",
      },
    ],
  },

  {
    name: "EP",
    path: "#",
    icon: "chevron-down",
    items: [
      {
        name: "Secteurs EP",
        path: "/sectors",
        icon: "compass",
        dataPriv: "view_sectors",
      },
      {
        name: "Calendrier des EP",
        path: "/ep",
        icon: "street-view",
        dataPriv: "view_ep_calendar",
        isEP: true,
      },
      {
        name: "Mes invitations aux EP",
        path: "/myeps",
        icon: "reply",
        dataPriv: "view_ep_calendar",
        isEP: true,
      },
      {
        name: "Gestion des invitations EP",
        path: "/allepinvitations",
        icon: "paper-plane",
        dataPriv: "manage_ep_invitations",
      },
      // {
      //   name: "Allocataires EP",
      //   path: "/recipients-ep",
      //   icon: "user-tag",
      //   dataPriv: "create_recipient_ep",
      // },
    ],
  },
  {
    name: "Outils",
    path: "#",
    items: [
      {
        name: "Paramètres",
        path: "/dynamic-constants",
        icon: "cog",
        dataPriv: "modify_constants",
      },
      {
        name: "Simulateur",
        path: "/simulator",
        icon: "calculator",
        dataPriv: "use_simulator",
      },
      {
        name: "Fichiers d'échange",
        path: "/file-exchange",
        icon: "exchange-alt",
        dataPriv: "file_exchange",
      },
      {
        name: "Simulateur SMS",
        path: "/smscron",
        icon: "sms",
        dataPriv: "file_exchange",
      },
    ],
  },
];

export default sidenavItems;
